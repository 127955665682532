import React from 'react';
import {
    connectStateResults
  } from 'react-instantsearch-dom';
export const Results = connectStateResults(({ searchState, children }) =>
        searchState && searchState.query ? (
            children
        ) : (
            <div></div>
        )
    );