import React from 'react';
import algoliasearch from 'algoliasearch/lite';
import {
    InstantSearch,
    Index,
    SearchBox,
    Configure,
    Hits
  } from 'react-instantsearch-dom';
import {ConfHit, WpHit} from '../Hit'
import { Results } from './results'
import styles from './Search.module.css'
const searchClient = algoliasearch('XQ1MNDZBU8', '54a8ec51792cbd22774aef375ef72d40');

const Search = () => (
  <div className={styles.searchContainer}>
      <InstantSearch searchClient={searchClient} indexName="confluence">
    <SearchBox className={styles.searchBar} />
      <Index indexName="confluence">
        <Results>
          <Configure hitsPerPage={4} />
          <Hits hitComponent={ConfHit} />
        </Results>
      </Index>
      <Index indexName="wp_searchable_posts">
        <Results>
          <Configure hitsPerPage={4} />
          <Hits hitComponent={WpHit} />
        </Results>
      </Index>
  </InstantSearch>
  </div>

);

export default Search;